import PropTypes from 'prop-types';
import { Fragment, useReducer } from 'react';
import { Link } from 'react-router-dom';
import get from 'lodash.get';

import { Table } from '../Common';
import CourseItem from './CourseItem';
import { electiveV0GroupsProps, courseStructureProps } from './propTypes';
import { SET_TAB_OPEN, SWAP_ALL_EXPANDED } from './constants';
import { reducer, getInitialState } from './reducer';

import './CourseStructure.scss';

const EmptyEntryPlaceholder = () => (
  <p style={{ margin: '20px 10px' }}>
    <i>
      The content of this section will change based on your previous selection
    </i>
  </p>
);

const renderRowValue = (value, link = null) => (
  <td>{link ? <Link to={link}>{value}</Link> : <span>{value}</span>}</td>
);

const TableHeader = ({ children }) => (
  <div className="study_period__header">
    <span>{children}</span>
  </div>
);

TableHeader.propTypes = {
  children: PropTypes.node.isRequired,
};

const renderTableWithText = (text, rows, key = null) => (
  <Fragment key={key}>
    <TableHeader>{text}</TableHeader>
    <Table.Table className="handbook_course_structure_table">
      <Table.Headers>
        <Table.Header>Code</Table.Header>
        <Table.Header>Title</Table.Header>
        <Table.Header>Credits</Table.Header>
      </Table.Headers>
      <Table.Rows>
        {rows.map(({ code, offeringTitle, offeringLink, credits }) => (
          <Table.Row key={code}>
            {renderRowValue(code, offeringLink)}
            {renderRowValue(offeringTitle, offeringLink)}
            {renderRowValue(credits)}
          </Table.Row>
        ))}
      </Table.Rows>
    </Table.Table>
  </Fragment>
);

const renderHeaderContent = (coreUnits) => (
  <>
    {coreUnits.length > 0 &&
      renderTableWithText('Complete the below', coreUnits)}
  </>
);

const renderAlternateCore = (electiveGroups) =>
  electiveGroups.map(
    ({ structureComponent, maxCredits, structureComponentEntries }) =>
      structureComponentEntries.length > 0 &&
      renderTableWithText(
        `Complete ${maxCredits} credit points from the following`,
        structureComponentEntries,
        structureComponent,
      ),
  );

const renderElectivesContent = (electiveGroups) =>
  electiveGroups.map(
    ({ structureComponent, maxCredits, structureComponentEntries }) =>
      structureComponentEntries.length > 0 &&
      renderTableWithText(
        `Complete ${maxCredits} credit points from the following options`,
        structureComponentEntries,
        structureComponent,
      ),
  );

const ElectivesV0Content = ({ electiveV0Groups: { totalCredits } }) =>
  totalCredits > 0 && (
    <>
      <div className="study_period__header">
        <span>Select electives to the value of {totalCredits}</span>
      </div>
    </>
  );

ElectivesV0Content.propTypes = {
  electiveV0Groups: electiveV0GroupsProps.isRequired,
};

const renderStudyPeriodEntries = (
  electiveGroupType,
  studyPeriodEntries,
  studyPeriod,
  yearIndex,
  studyPeriodIndex,
  state,
  createClickFunc,
) => {
  const isAlternateCore = electiveGroupType === 'core';
  const shouldNotDisplay =
    (studyPeriodEntries &&
      isAlternateCore &&
      studyPeriodEntries.electiveGroups.core.length === 0) ||
    (studyPeriodEntries &&
      !isAlternateCore &&
      studyPeriodEntries.electiveGroups.options.length === 0);
  if (shouldNotDisplay) {
    return null;
  }
  const stateKey = isAlternateCore
    ? `${yearIndex}.${studyPeriodIndex}.alternatecore`
    : `${yearIndex}.${studyPeriodIndex}.options`;
  const ariaControl = isAlternateCore
    ? `Year-${yearIndex + 1}-${studyPeriod.replace(/ /g, '-')}-Alternate-Core`
    : `Year-${yearIndex + 1}-${studyPeriod.replace(/ /g, '-')}-Options`;
  const ariaLabel = isAlternateCore
    ? `Year ${yearIndex + 1} ${studyPeriod} Alternate Core`
    : `Year ${yearIndex + 1} ${studyPeriod} Options`;
  return (
    <CourseItem
      key={
        isAlternateCore
          ? `${studyPeriod}-alternatecore`
          : `${studyPeriod}-options`
      }
      title={isAlternateCore ? `Alternate Core` : `Options`}
      type="pathway"
      ariaLabel={ariaLabel}
      ariaControl={ariaControl}
      isOpen={get(state, [`${stateKey}`, 'open'], false)}
      isOverride={get(state, [`${stateKey}`, 'override'], false)}
      setOpen={createClickFunc(stateKey)}
    >
      {isAlternateCore &&
        renderAlternateCore(studyPeriodEntries.electiveGroups.core)}
      {!isAlternateCore &&
        renderElectivesContent(studyPeriodEntries.electiveGroups.options)}
    </CourseItem>
  );
};

const renderStudyPeriod = (
  yearLevel,
  studyPeriod,
  studyPeriodIndex,
  yearIndex,
  studyPeriodEntries,
  state,
  createClickFunc,
  isYearZeroOnly = false,
) => {
  // Hide the "NA" study period groups if they're empty
  if (studyPeriodEntries === null && studyPeriod === 'NA') {
    return null;
  }

  if (studyPeriod === 'NA') {
    return (
      <Fragment key={`${studyPeriod}-core`}>
        {renderHeaderContent(studyPeriodEntries.core)}
        {
          // Do not display additional 'Alternate Core' tab within year level 0
          // since this tab is already denoted 'Alternate Core'
          yearLevel === 'NA'
            ? renderAlternateCore(studyPeriodEntries.electiveGroups.core)
            : renderStudyPeriodEntries(
                `core`,
                studyPeriodEntries,
                studyPeriod,
                yearIndex,
                studyPeriodIndex,
                state,
                createClickFunc,
              )
        }
        {
          // Do not display additional 'Options' tab within year level 0
          // since this tab is already denoted 'Options'
          yearLevel === 'NA' && !isYearZeroOnly
            ? renderElectivesContent(studyPeriodEntries.electiveGroups.options)
            : renderStudyPeriodEntries(
                `options`,
                studyPeriodEntries,
                studyPeriod,
                yearIndex,
                studyPeriodIndex,
                state,
                createClickFunc,
              )
        }
        <ElectivesV0Content
          electiveV0Groups={studyPeriodEntries.electiveV0Groups}
        />
      </Fragment>
    );
  }

  const stateKey = `${yearIndex}.${studyPeriodIndex}`;
  const ariaLabel = `Year ${yearIndex + 1} ${studyPeriod}`;

  return (
    <CourseItem
      key={`${studyPeriod}-core`}
      title={studyPeriod}
      type="semester"
      ariaLabel={ariaLabel}
      ariaControl={stateKey}
      isOpen={get(state, [`${stateKey}`, 'open'], false)}
      isOverride={get(state, [`${stateKey}`, 'override'], false)}
      setOpen={createClickFunc(stateKey)}
    >
      {renderHeaderContent(studyPeriodEntries.core)}
      {renderStudyPeriodEntries(
        `core`,
        studyPeriodEntries,
        studyPeriod,
        yearIndex,
        studyPeriodIndex,
        state,
        createClickFunc,
      )}
      {renderStudyPeriodEntries(
        `options`,
        studyPeriodEntries,
        studyPeriod,
        yearIndex,
        studyPeriodIndex,
        state,
        createClickFunc,
      )}
      <ElectivesV0Content
        electiveV0Groups={studyPeriodEntries.electiveV0Groups}
      />
    </CourseItem>
  );
};

const CourseStructure = ({ structure }) => {
  const [state, dispatch] = useReducer(reducer, getInitialState(structure));
  const createClickFunc = (index) => (open, override) =>
    dispatch({
      type: SET_TAB_OPEN,
      index,
      value: { open, override },
    });

  // Function to check if it's a special case
  const isSpecialCase = (state, structure) => {
    const isNALevel = structure.length === 1 && structure[0].yearLevel === 'NA';
    return isNALevel && state['0.0.options'] && state['0.0.options'].open;
  };

  const shouldExpandAll = (state, structure) => {
    if (isSpecialCase(state, structure)) {
      // If it's the special case, check specifically for `0.0.options`
      return !state['0.0.options'].open;
    }

    // General case for checking top-level keys
    return Object.entries(state)
      .filter(([key]) => !key.includes('.'))
      .every(([, { open }]) => !open);
  };

  return (
    <>
      <div className="course_structure__header">
        <h2>Course structure</h2>
        <button
          id="course_structure__expand_collapse_all"
          type="button"
          className="handbook__button_as_link pink"
          onClick={() => {
            const expandAll = shouldExpandAll(state, structure);
            dispatch({ type: SWAP_ALL_EXPANDED, value: expandAll });
          }}
        >
          {shouldExpandAll(state, structure) ? 'Expand all' : 'Collapse all'}
        </button>
      </div>
      <section className="course_structure">
        {structure.map(({ yearLevel, yearLevelEntries }, yearIndex) => {
          const isNALevel = yearLevel === 'NA';
          const isYearZeroOnly = structure.length === 1 && isNALevel;
          // Hide NA year level if no entries are available
          if (isNALevel && yearLevelEntries.length === 0) {
            return null;
          }

          return isYearZeroOnly ? (
            // Only display the content when isYearZeroOnly is true
            yearLevelEntries.length === 0 ? (
              <EmptyEntryPlaceholder />
            ) : (
              yearLevelEntries.map(
                ({ studyPeriod, studyPeriodEntries }, studyPeriodIndex) =>
                  renderStudyPeriod(
                    yearLevel,
                    studyPeriod,
                    studyPeriodIndex,
                    yearIndex,
                    studyPeriodEntries,
                    state,
                    createClickFunc,
                    isYearZeroOnly,
                  ),
              )
            )
          ) : (
            <CourseItem
              key={yearLevel}
              title={isNALevel ? 'Options' : `Year ${yearLevel}`}
              type={isNALevel ? 'pathway' : 'year'}
              ariaLabel={isNALevel ? 'Options' : `Year ${yearLevel}`}
              ariaControl={isNALevel ? 'Options' : `Year-${yearLevel}`}
              isOpen={get(state, [yearIndex, 'open'], false)}
              isOverride={get(state, [yearIndex, 'override'], false)}
              setOpen={createClickFunc(yearIndex)}
            >
              {yearLevelEntries.length === 0 ? (
                <EmptyEntryPlaceholder />
              ) : (
                yearLevelEntries.map(
                  ({ studyPeriod, studyPeriodEntries }, studyPeriodIndex) =>
                    renderStudyPeriod(
                      yearLevel,
                      studyPeriod,
                      studyPeriodIndex,
                      yearIndex,
                      studyPeriodEntries,
                      state,
                      createClickFunc,
                      isYearZeroOnly,
                    ),
                )
              )}
            </CourseItem>
          );
        })}
      </section>
    </>
  );
};

CourseStructure.propTypes = {
  structure: courseStructureProps.isRequired,
};

export default CourseStructure;
